import React, { ReactElement } from "react";

export default function CardProcessor(): ReactElement {
    return (
        <section className="mt-10 lg:mt-16">
            <h2 className="text-center text-xl font-bold  lg:text-3xl mb-8 lg:mb-10">
                What Is a Credit Card Processor?
            </h2>
            <div className="text-center font-light text-sm lg:text-base">
                A credit card processor is a company or financial institution
                that facilitates credit card transactions between the merchant
                and the customer. In today's digital age, transactions can occur
                anywhere - in person, online, over the phone, or by mail. The
                processor acts as a middleman, transmitting transaction data
                between the merchant, the customer, and the various entities
                involved in the payment process, such as the card network, the
                issuing bank, and the acquiring bank. The credit card processor
                also ensures the transaction is secure, verifies the card's
                validity, and approves or declines the payment. The processor
                charges a fee to the merchant for their services, typically a
                percentage of the transaction amount, and may also charge
                additional fees for specific services or transactions.
            </div>
        </section>
    );
}
